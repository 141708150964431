import * as Sentry from './sentry.js';

import.meta.glob([
  '../images/**',
  '../fonts/**',
]);


import $ from 'jquery';
window.$ = $;

import "owl.carousel";
import "owl.carousel2.thumbs/dist/owl.carousel2.thumbs.min.js";

//jquery config
$(function() {
  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      'Accept-Language': $('meta[name="language"]').attr('content')
    }
  });
});

import "bootstrap";

import { createApp } from 'vue/dist/vue.esm-bundler';
window.createApp = createApp;

import axios from 'axios';
window.axios = axios;

import VueAxios from 'vue-axios'
window.VueAxios = VueAxios;

//axios config
window.axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
  'Accept-Language': document.querySelector('meta[name="language"]').getAttribute('content')
};

import mitt from 'mitt';
window.emitter = mitt();

import "lightbox2/dist/js/lightbox.min.js";

import "@fancyapps/fancybox/dist/jquery.fancybox.min.js"

import "multiple-select/dist/multiple-select.min.js";
import "./components/bootstrap-filestyle.js";
import "./components/component-forms.js";
import "select2";

import 'datatables.net-bs4';
import 'datatables.net-fixedheader-bs4';
import 'datatables.net-responsive-bs4';

import "./frontend/header.js";
import "./frontend/carousel.js";
import "./frontend/fancybox.js";
import "./frontend/faq.js";
import "./frontend/iframe.js";
import "./frontend/form.js";
import "./frontend/product.js";
import "./frontend/myaccount-product-list.js";
import "./frontend/myaccount-export-settings.js";
import "./frontend/infoblock.js";
import "./frontend/scroll-button.js";
import "./frontend/table.js";
import "./frontend/datatables-extensions";

import "./vue/api-token.js";
import "./vue/product-notes.js";
import "./vue/product-questions.js";
import "./vue/cart.js";
import "./vue/order-confirm.js";
import "./vue/favorites.js";
import "./vue/selected.js";
import "./vue/smartphone-scanner.js";

$("#perpage").on('change', function (e) {
  e.preventDefault();
  $.get('/ajax/settings/listperpagelimit/' + $(this).val()).done(function () {
    if (window.location.search.search('page')) {
      var params = window.location.search;
      params = params.replace(/\?page=[0-9]*/, '?page=1');
      params = params.replace(/&page=[0-9]*/, '&page=1');
      window.location.href = window.location.origin + window.location.pathname + params;
    } else {
      location.reload();
    }
  });
});

$(".switch-currency").on('click', function (e) {
  e.preventDefault();
  $.get('/ajax/settings/currency/' + $(this).data('currency'), function () {
    location.reload();
  });
});

$("a").on('mousedown', function (e) {
  let event = $(this).data('event');
  let eventContactCategory = $(this).data('event-contact-category');
  if(event && eventContactCategory) {
    dataLayer.push({event: event, params : {'contactCategory' : eventContactCategory}});
  }
});

$(function () {
  $(".html5-video")
    .modal({
      show: false
    })
    .on("hidden.bs.modal", function () {
      $(this)
        .find("video")[0]
        .pause();
    });

  $('body').tooltip({
    selector: '[data-toggle="tooltip"]'
  });
});

$('#footer #active-campaign').on('submit', "form", function (e) {
  var ja = $('#footer #active-campaign #field_1Ja').is(":checked");
  var nee = $('#footer #active-campaign #field_1Nee').is(":checked");
  var firstname = $('#footer #active-campaign #firstname').val();
  var email = $('#footer #active-campaign #email').val();

  if((ja || nee) && firstname && email) {
    dataLayer.push({
      'event': 'newsletter_subscription',
      'params': {
        'customer': ja ? 'Ja' : 'Nee'
      }
    });
  }
});

$("#sortorder_dropdown").on('change', function (e) {
  e.preventDefault();
  addParamsToUrlAndRedirect('sortorder', $(this).val());
});

$(".show-all").on('click', function (e) {
  e.preventDefault();
  if (typeof $(this).data('show-class') !== 'undefined') {
    $(this).closest('.products-list').find('.' + $(this).data('show-class')).css('display', 'flex');
  } else if (typeof $(this).data('show-id') !== 'undefined') {
    $(this).closest('.products-list').find('#' + $(this).data('show-id')).css('display', 'flex');
  }

  $(this).hide();
});

function addParamsToUrlAndRedirect(param, value, removePage = true) {
  var params = window.location.search;

  if (removePage) {
    params = params.replace(/\?page=[0-9]*/, '?page=1');
    params = params.replace(/&page=[0-9]*/, '&page=1');
  }

  params = params.replace('?', '');

  // if(params.indexOf(param) > 0) {
  //   params = params.replace(/&page=[0-9]*/, '').replace(/\?page=[0-9]*/, '');
  // }

  if (params.length > 0) {
    params = '?' + params + '&' + param + '=' + value;
  } else {
    params = '?' + param + '=' + value;
  }

  window.location.href = window.location.origin + window.location.pathname + params;
}
